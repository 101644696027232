import React from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import Hero from "../components/hero";
import Body from "../components/body";
import Seo from "../components/seo/seo";

const ERROR = props => {
  return (
    <>
      <Seo />
      <Header />
      <Hero />
      <Body />
      <Footer />
    </>
  );
};

export default ERROR;